import React from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faMapMarkerAlt, faClock, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import Layout from '../components/layout/Layout'
import AnchorSlug from '../components/AnchorSlug'

import PageData from '../data/careers.yaml'

const Careers = () => (
  <Layout>
    <section id="careers" className={`${!PageData.jobs ? "h-screen" : ""}`}>
      <div className="container mx-auto p-4 pt-16 text-center">
        <h1 className="text-primary-500 font-semibold tracking-wide uppercase">Company</h1>
        <h1 className="md:text-4xl text-3xl font-semibold tracking-tight justify-center mt-2">{PageData.title}</h1>
        <p className="md:text-lg font-light my-4">{PageData.overview}</p>
      </div>
    </section>

    {!PageData.jobs ? null : 
      PageData.jobs.map((job, i) => {
        return (
          <section key={job.id} id={job.id} className="relative my-10 py-10 md:px-0 px-4 md:py-20 bg-gray-100 border border-grey shadow-inner">
            <div className="container px-8 py-0 mx-auto flex flex-col space-y-4 md:text-left text-center">
              <AnchorSlug to={job.id} title={job.title} text={job.title} className="md:mx-0 mx-auto"/>
              <div className='flex flex-col space-y-2 text-sm text-gray-700'>
                <span className="space-x-2">
                  <FontAwesomeIcon icon={faBuilding} size="xs"/>
                  <span className="font-semibold">{job.subtext}</span>
                </span>
                <span className="space-x-2">
                  <FontAwesomeIcon icon={faMapMarkerAlt} size="xs"/>
                  <span className="font-semibold">{job.location}</span>
                </span>
                <span className="space-x-2">
                  <FontAwesomeIcon icon={faClock} size="xs"/>
                  <span className="font-semibold">{job.length}</span>
                </span>
              </div>
              <p class="md:text-md py-2 space-y-3 font-light">
                {!job.paragraphs ? null : 
                  job.paragraphs.map((paragraph, i) => {
                    return <p>{paragraph}</p>
                  })}
              </p>
              <div><Link to={job.link}>Read More</Link></div>
            </div>
          </section>
        )
    })}

    {!PageData.jobs ? null : 
      <section id="contact-us" className="md:px-0 px-4 bg-transparent">
        <div className="container flex mx-auto">
          <span className="text-lg space-x-2 mx-auto">
            <FontAwesomeIcon icon={faCaretUp} />
            <Link to="/contact">Enquire today for more information.</Link>
            <FontAwesomeIcon icon={faCaretUp} />
          </span>
        </div>
      </section>
    }
  </Layout>
)
export default Careers