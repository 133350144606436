import React from 'react'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/free-solid-svg-icons'

const AnchorSlug = ({ to, title, text, className = '' }) => {
  return (
    <div className='inline-flex'>
      <AnchorLink to={`#${to}`} title={`${title}`} className={`group ${className}`}>
        <h2 className="font-semibold text-xl text-primary-500">
          {text} <FontAwesomeIcon icon={faLink} size="xs" className="absolute lg:flex mt-2 ml-2 text-xl text-primary-500 group-hover:visible invisible"/>
        </h2>
      </AnchorLink>
    </div>
  )
}

export default AnchorSlug